import styled, { css } from "styled-components"
import banner from "../../public/assets/illustrations/banner-bg.png"

export const Banner = styled.div`
  background-image: url(${banner});
  padding: 5rem 1rem;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 450px;
  h1 {
    color: #fff;
    font-weight: 300;
    br {
      &:nth-child(2) {
        display: none;
      }
    }
  }
  @media only screen and (min-width: 1024px) {
    min-height: 75vh;
    display: flex;
    h1 {
      font-size: 81px;
      font-weight: 400;
      text-transform: uppercase;
      br {
        &:nth-child(2) {
          display: block;
        }
      }
    }
  }
`

export const Paragraph = styled.div`
  @media (min-width: 1024px) {
    p {
      font-size: 18px;
      line-height: 35px;
    }
  }
`

export const PrivacyWrapper = styled.div`
  padding: 4rem 0;
  h2 {
    margin-bottom: 3rem;
  }
`

export const BannerText = styled.div`
  ${({ center }) =>
    center &&
    css`
      display: flex;
      align-items: center;
    `}
  h1 {
    line-height: 42px;
  }
  @media only screen and (min-width: 1024px) {
    h1 {
      line-height: 85px;
    }
  }
  @media only screen and (min-width: 1200px) {
    padding-left: 3rem;
  }
  @media only screen and (min-width: 1700px) {
    padding-left: 5rem;
  }
`

export const InfoWrapper = styled.div`
  padding: 1rem 0;
  @media only screen and (min-width: 1024px) {
    padding: 2.5rem 0;
    display: flex;
    justify-content: space-around;
  }
`

export const Info = styled.div`
  margin: 1rem 0;
  h3 {
    font-weight: 700;
    color: #272727;
    margin: 0;
    text-align: center;
  }
  p {
    margin: 0;
    color: #272727;
    text-align: center;
  }
  @media only screen and (min-width: 1024px) {
    margin: 0;
    h3 {
      font-size: 32px;
    }
    p {
      font-size: 20px;
    }
  }
`
export const AboutWrapper = styled.div`
  background-color: #0a5fc9;
  padding: 2rem 1rem;
  @media only screen and (min-width: 1024px) {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    padding: 1rem;
  }
`

export const AboutText = styled.div`
  h2 {
    color: #fff;
    font-weight: 700;
  }
  p {
    color: #fff;
    /*
    &:nth-child(3) {
      color: red;
    }
    */
  }
  img {
    &:nth-child(7) {
      margin-bottom: -120px;
    }
  }
  @media only screen and (min-width: 1024px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    padding-bottom: 0;
    flex: 0 0 50%;
    width: 50%;
    margin: auto;
    max-width: 510px;
    figure {
      display: none;
    }
    span {
      display: block;
    }
    img {
      display: none;
    }
    h2 {
      font-size: 45px;
    }
    p {
      font-size: 18px;
      line-height: 35px;
      &:nth-child(5) {
        margin-bottom: 2rem;
      }
    }
  }
  @media only screen and (min-width: 1200px) {
    flex: ;
  }
  @media only screen and (min-width: 1200px) {
    flex: 0 0 55%;
    h2 {
      margin-bottom: 2.5rem;
    }
  }
`

export const AboutImages = styled.div`
  display: none;
  figure {
    margin: 0;
  }
  span {
    padding: 0;
    display: block;
    width: 100% !important;
  }
  img {
    width: 100%;
    object-fit: cover;
  }
  @media only screen and (min-width: 1024px) {
    display: block;
    flex: 0 0 50%;
    width: 50%;
    img {
      margin: 0;
      display: block;
      height: 50%;
    }
  }
  @media only screen and (min-width: 1200px) {
    flex: 0 0 45%;
    width: 45%;
  }
`
export const HistoryWrapper = styled.div`
  margin-top: 150px;
  padding: 1rem;
  display: flex;
  flex-direction: column-reverse;
  @media only screen and (min-width: 1024px) {
    margin-top: 0;
    padding: 0;
    flex-wrap: wrap;
    flex-direction: row;
  }
`

export const HistoryText = styled.div`
  h2 {
    color: #242424;
    font-weight: 700;
  }
  p {
    color: #272727;
    line-height: 27px;
  }
  @media only screen and (min-width: 1024px) {
    flex: 0 0 50%;
    width: 50%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 520px;
    margin: auto;
    h2 {
      font-size: 45px;
    }
    p {
      line-height: 35px;
    }
  }
  @media only screen and (min-width: 1200px) {
    h2 {
      margin-bottom: 2.5rem;
    }
  }
  @media only screen and (min-width: 1600px) {
    margin-left: 5%;
  }
`

export const HistoryImage = styled.div`
  @media only screen and (min-width: 1024px) {
    width: 45%;
    flex: 0 0 45%;
    span {
      width: 100% !important;
      display: block;
    }
  }
`

export const Values = styled.div`
  margin: 3rem 0;
  @media only screen and (min-width: 1024px) {
    margin: 10rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`

export const Value = styled.div`
  margin: 1.5rem 0;
  h3 {
    color: #0a5fc9;
    font-weight: 700;
    font-size: 28px;
  }
  p {
    line-height: 26px;
    font-weight: 300;
    color: #242424;
  }
  @media only screen and (min-width: 1024px) {
    margin: 4rem 0;
    padding: 1rem 0.5rem;
    flex: 0 0 33%;
    max-width: 420px;
    h3 {
      font-size: 45px;
      margin-bottom: 2rem;
    }
    p {
      line-height: 28px;
    }
    ${props =>
      props.bordered &&
      css`
        border-right: 2px solid #f2f6fa;
        border-left: 2px solid #f2f6fa;
      `}
  }
  @media only screen and (min-width: 1200px) {
    flex: 0 0 31%;
    padding: 1rem;
  }
`

export const Gallery = styled.div`
  margin: 6rem 0;
  div {
    flex-wrap: wrap;
    justify-content: space-between;
    display: flex;
    align-items: center;
  }
  @media only screen and (min-width: 1024px) {
    margin-top: 5rem;
    margin-bottom: 10rem;
  }
`

export const ImageWrapper = styled.div`
  flex: 0 0 calc(50% - 0.5rem);
  margin: 0.25rem;
  width: calc(50% - 0.5rem);
  div {
    width: 100%;
  }
  span {
    display: block;
    width: 100% !important;
  }
  figure {
    width: 100%;
  }
  img {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  @media only screen and (min-width: 1024px) {
    flex: 0 0 32%;
    width: 32%;
    img {
      margin-bottom: 0;
      width: 100%;
    }
  }
`
