import React from "react"
import { Wrapper } from "./styles"

const Container = props => {
  const { children, fullscreen } = props || {}

  return <Wrapper fullscreen={fullscreen}>{children}</Wrapper>
}

export default Container
